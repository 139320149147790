@import '/src/theme/main.scss';

.hidden-date-picker {
  display: none;
}

.step-one-title {
  align-items: center !important;
}

.step-one-form-container {
  position: relative;
  height: auto;
  padding: 2rem;
}

.step-one-main-container {
  display: grid;
  justify-content: center;
  position: relative;
  z-index: 5;
}

.step-one-dropdown-holder {
  width: 26rem;
  padding: 2rem;
  position: relative;
  height: auto;
}

.step-one-button {
  margin-bottom: 1rem !important;
}

.step-one-datePicker {
  border-radius: 3px;
  border: 1px #c4c4c4 solid !important;
  padding: 4px 12px !important;
  height: 53px !important;
  margin-top: 0.5rem;
  margin-bottom: 0 !important;
  font-size: 16px;
  width: 100% !important;
  font-family: 'iranyekan' !important;
  box-shadow: none !important;

  &.error {
    border: 1px solid #D32F2F !important;
  }
}

.rmdp-container {
  width: 100% !important;
}

.step-one-datePicker:focus {
  outline: none;
  border: 2px solid #1976d2 !important;
  box-shadow: none !important;
}

.step-one-calender__label {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
}

// .step-one-image {
//   width: 20vmin;
//   height: 20vmin;
// }

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.muirtl-l6hdvs-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100%;
}

.step-one-select-holder {
  // width: 26rem;
  // min-width: 26rem !important;
}
.rmdp-time-picker input {
  font-family: iranyekan !important;
}

// @media only screen and (max-width: 800px) {
//   .step-one-datePicker {
//     width: 95% !important;
//   }
// }
