@import '/src/theme/main.scss';
.mainCoins{
    display: flex;
    flex-direction: row-reverse;
    //justify-content: space-around;
    margin: 20px;
    margin-top: 5px;
    @media (max-width: 800px) {
        flex-direction: column;
    }
}
.studentCoins{
    margin: 20px;
    text-align: center;
    width: 50%;
    color: coral;
    border-radius: 10px;
   // box-shadow: rgba(241, 182, 148, 0.5) 0px 3px 10px;
    padding: 20px;
    font-size: xx-large;
        font-weight: 700;
    &__media{
        padding: 7px;
        width: 80%;
        margin-bottom: 30px;
    }
    @media (max-width: 800px) {
        margin: 10px;
        width: 95%;
    }
}
.myCoins{
    height: max-content;
    background-color: rgb(255, 214, 221);
    box-shadow: rgba(241, 148, 184, 0.5) 0px 3px 10px;
    color:crimson;
    margin: 20px;
    margin-top: 40px;
    padding: 20px;
    text-align: center;
    width: 40%;
    border-radius: 5px;
    font-size: xx-large;
    &__media{
        margin-top: 30px;
        width: 80%;
    }
    @media (max-width: 800px) {
        margin: 10px;
        width: 95%;
    }
}
.pinki{
    width: 82%;
    background-color: lightpink;
    box-shadow: rgba(241, 148, 184, 0.5) 0px 3px 10px;
    color:crimson;
    &__content{
       margin-top: 15px;
       @media (max-width: 800px) {
        font-size: 20px;
        }
    }
    &__media{
        margin: 10px;
        width: 64px;
        @media (max-width: 800px) {
            width: 40px;
        }
    }
}
.inArow{
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.byMyself{
    justify-content: center;
    background-color: rgb(255, 223, 228);
    box-shadow: rgba(241, 148, 184, 0.5) 0px 3px 10px;
    color:crimson;
    margin: auto;
    margin-top: 85px;
    margin-right: 100px;
    width: 750px;
    height: 600px;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    font-size: xx-large;
    &__media{
        width: 80%;
    }
    @media (max-width: 800px) {
        margin: 10px;
        margin-top: 80px;
        width: 95%;
        height: 400px;
    }
}
.sooratiii{
    //background-color: rgb(255, 240, 242);
}
.coinTree{
    margin-top: 130px;
    width: 600px;
    @media (max-width: 800px) {
        display: none;
    }
}
