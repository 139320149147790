@import '/src/theme/main.scss';

.reset-password-button {
  background-color: rgb(99, 36, 200) !important;
}

.reset-password-avatar {
  background-color: rgb(99, 36, 200) !important;
}

// .Toastify__toast--error {
//   background: rgb(49, 3, 102) !important;
// }

.Toastify__toast--success {
  background: rgb(4, 61, 61) !important;
}
