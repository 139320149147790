@import '../../theme/main.scss';

.student-profile-card-default-page-setting {
  display: flex;
  justify-content: center;
  align-items: center !important;
  border-radius: 15px;
}

.student-profile-modal-card-container {
  width: 300px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.student-profile-modal-card-upper-part {
  height: 100px;
  background-color: #6617cb;
  background-image: linear-gradient(315deg, #6617cb 0%, #cb218e 74%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.student-profile-card-upper-part__image img {
  transform: translate(0px, 50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white !important;
  padding: 3px;
}

.student-profile-card-lower-part {
  text-align: center;
  margin-top: 54px !important;
}

.student-profile-card-lower-part__name {
  text-align: center;
}

.student-profile-card-lower-part__name__firstname {
  display: block !important;
  text-align: right;
  margin: 15px;
}

.student-profile-card-lower-part__name__lastname {
  display: block !important;
  text-align: right;
  margin: 15px;
}

.student-profile-card-lower-part__bio {
  // margin-top: 30px;
  text-align: justify;
}

.student-profile-card-lower-part__bio__text {
  margin-right: 30px;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
  text-align: justify;
}

.student-profile-card-lower-part__age {
  margin-top: 20px;
  margin-bottom: 20px;
}

// .student-profile-modal-card {
//   width: 300px !important;
//   height: 500px !important;
// }

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 300px;
  border-radius: 15px;
}

h2#alert-dialog-title {
  padding: 0px;
}

.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
  padding: 0px;
}
