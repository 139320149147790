@import '/src/theme/main.scss';

.course-comment-student {
  &__card {


    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    padding: 32px !important;
    border-radius: 1.15rem;
    position: relative;
    word-wrap: break-word;
    background-color: #e7f1fc; 
    margin: 35px 16px !important;
    box-shadow:  -4px 5px 10px -5px rgba(0, 0, 0, 0.65) !important;
    z-index: 2;

    @media(max-width:600px){
      margin: 35px -5px !important;
    }
  }

  &__text{
    
    font-weight: 700;
    color: rgb(128, 128, 128);
    margin-bottom: 32px;
  }

  &__name{
    color: #436da7 !important;
    font-weight: 705;
  }

  &__date{
    font-size: 15px !important;
    color: gray !important;
}
}

.course-comment-student__card:before {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #e7f1fc;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.90) !important;
    z-index: 1;
}

.course-comment-student__card:after{
    
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform:translate(-30px, -2px);
    width: 10px;
    z-index: 1;
    
}

.comment-avatar{
    width: 80px !important;
    height: 80px !important;
    margin-top: auto !important;

    @media(max-width:1500px){
      width: 70px !important;
      height:  70px !important;
    }
    @media(max-width:1200px){
      width: 70px !important;
      height:  70px !important;
    }
    @media(max-width:900px){
      width: 60px !important;
      height: 60px !important;
    }
    @media(max-width:750px){
      width: 50px !important;
      height:  50px !important;
    }
}