@import '/src/theme/main.scss';

.navbar {
  &__search-bar {
    display: flex;
    padding: 0 7%;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    margin-top: 85px;
  }
}
.desktop_navbar {


  @media screen and (max-width: 2000px) {
    &__logo {
      margin: 8px 40px 8px 10px;
      width: 70px !important;


    }

    &__signup {
      margin-right: auto;
      margin-left: 10%;
    }
  }

  @media screen and (max-width: 600px) {
    &__logo {
      margin: 8px 10px 8px 10px;
      width: 60px !important;


    }
  }
}
