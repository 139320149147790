@import '../../theme/main.scss';

.about-me{
    
}

.wrapper
{
    box-shadow:rgba(0, 0, 0, 0.60) 0px 2px 8px !important;
    border: 3px solid rgb(25, 114, 129);
    padding: 20px;
    border-radius: 25px;
}

.editor-wrapper{
    direction: ltr;
    
}

.editor-main{
    min-height: 50vmin !important;
}