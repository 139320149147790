@import '/src/theme/main.scss';
.Apic{
    margin-top: 20px;
    width: 50px;
    height: fit-content;
}

.txt{
  margin-right: 20px;
  margin-top: 28px;
  margin-bottom: 28px;
  width: 80%;
  display: flex;
  flex-direction: row;
}
.Clful{
  font-weight: 800;
  border-radius: 1%;
  border:#f74444;
  margin-left: 65px;
  margin-right: 65px;
  margin-bottom: 20px;
  padding: 10px;
  @media (max-width: 800px) {
    margin: auto;
}
  //background-color: #fdd8c2;
}

#drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 480px;
    font-family: sans-serif;
    margin: 100px auto;
    padding: 20px;
  }
  #drop-area.highlight {
    border-color: rgb(104, 16, 16);
  }
  p {
    margin-top: 0;
  }
  .my-form {
    margin-bottom: 10px;
  }
  #gallery {
    margin-top: 10px;
  }
  #gallery img {
    width: 150px;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .button {
    display: inline-block;
    padding: 10px;
    background: #ccc;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .button:hover {
    background: #ddd;
  }
  #fileElem {
    display: none;
  }

  .App {
    display: flex;
    flex-direction: column;
    margin: auto;
    @media (max-width: 800px) {
      margin: auto;
  }
  }
  label {
    width: 350px;
    box-sizing: border-box;
  }
  .Uplr{
    margin: auto;
    margin-bottom: 20px;
    justify-content: center;
    text-align: center;
  }
  .MyHeader{
    font-size: x-large;
    color: rgb(104, 16, 16);
    padding-bottom: 15px ;
  }
  .Box{
    padding: 10px;
    margin-top: 3px;
    margin-right: 20px;
    @media (max-width: 600px) {
      margin: auto;
  }
  }
  .editor{
    background-color: rgb(255, 238, 252);
    min-height: 350px;
    border-radius: 1%;
    margin-bottom: 20px;
    padding: 15px;
    border-style:groove;
    border-color:rgb(104, 16, 16);
  }
  .mytxt{
    font-size: large;
    font-weight: 800;
    color: rgb(104, 16, 16);
  }
  .Bt{
    width: max-content;
    height: 45px;
    padding: 1px;
    margin-top: 5px;
    margin-right: auto;
    border-radius: 10%;
    background-color: rgb(104, 16, 16);
    border-style:double;
    border-color:rgb(255, 232, 239);
    &__txt{
      margin: auto;
      font-size: medium;
      font-weight: 800;
      color: #ffffff;
    }
  }
  .ovL{
    padding: 2px;
    font-weight: 600;
    font-size: 14px;
    background-color:rgb(255, 238, 252);
    margin: 2px;
    height: 43px;
    width: 255px;
    border-radius: 1%;
  }
  .MyUp{
    background-color: rgb(255, 238, 252);
    padding: 15px;
    margin-bottom: 7px;
    border-style:groove;
    border-color:rgb(104, 16, 16);
  }
  .Border{
    border-style:groove;
    border-color:rgb(104, 16, 16);
  }
  .color{
    background-color:  rgb(255, 255, 255);
  }