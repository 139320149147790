@import '/src/theme/main.scss';
.afterMyC-a{
  margin-top: 338px;
  text-align: center;
};

.My-courses-section {
  text-align: center;
  margin-bottom: 96px;

  &__title {
    font-size: 24px;
    font-weight: 800;
    color: $c-black;
    margin-bottom: 32px;
  }

  &__highlight {
    color: #ff8c77;
  }

  .My-carousal-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-slide {
      padding: 10px 0;
      width: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-button-prev {
      width: 48px;
      height: 110%;
      top: 0;
      right: 0 !important;
      background: linear-gradient(to right, transparent 0%, white 20%);
      padding: 10px;
      &::after {
        font-weight: bolder;
        color: #666;
        font-size: 24px;
      }
    }
    .swiper-button-next {
      width: 48px;
      height: 110%;
      top: 0;
      left: 0 !important;
      background: linear-gradient(to left, transparent 0%, white 20%);

      &::after {
        font-weight: bolder;
        color: #666;
        font-size: 24px;
      }
    }
  }
}
.bmImg{
  width: 32px;
  height: 30px;
  margin-top: 301px;
  margin-right: 17%;
  position: absolute;
  @media (max-width: 800px) {
    margin-right: 16%;
  }
  @media (max-width: 600px) {
    width: 28px;
    margin-right: 2%;
    margin-top: 298px;
  }
}