@import '/src/theme/main.scss';

.Toastify__toast--error {
  color: #fff !important;
}

.course-page {
  max-width: 1048px;
  margin: auto;
}

.course-header {
  margin: 8px;
  padding: 32px 16px 16px;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__categories {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    margin-right: 18px !important;
  }

  &__rating {
    direction: ltr;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 20px;
    font-weight: 800;
    color: rgb(253, 200, 64);

    &--number {
      margin-left: 8px;
    }
  }

  &__description {
    margin-top: 16px;
    text-align: justify;
    margin-left: 32px;
  }

  &__register {
    background-color: #29a877;
    color: $c-white;
    border: 1px solid rgba(37, 93, 173, 0.25);
    padding: 8px 16px;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(41, 168, 119, 0.35);
    font-size: 16px;
    cursor: pointer;
    margin: 0 16px;

    &:hover {
      background-color: #249468;
    }
  }

  &__goto-class {
    margin: 0 16px;
  }

  &__goto-times {
    background-color: rgb(37, 93, 173);
    color: $c-white;
    border: 1px solid rgba(37, 93, 173, 0.25);
    padding: 8px 16px;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(37, 93, 173, 0.35);
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: rgb(31, 79, 146);
    }
  }

  &__img {
    width: 100%;
    max-width: 400px;
    height: 250px;
    background-color: $c-gray-85;
    flex-shrink: 0;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    margin-top: 64px;

    img {
      width: 100%;
      max-width: 400px;
      height: 250px;
      border-radius: 8px;
    }
  }

  &__class-info {
    padding: 32px 0;
    display: flex;
    align-items: center;
  }

  &__remain {
    font-weight: 700;
    color: rgb(241, 93, 93);
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    &__first-section-wrapper {
      display: flex;
      flex-direction: column;
    }
    &__goto-times {
      align-self: center;
    }
    &__description {
      margin-left: 0;
    }

    &__class-info {
      flex-direction: column;
    }

    &__register {
      margin: 16px 0 0;
    }

    &__goto-class {
      margin: 16px 0 0;
    }

    &__remain {
      text-align: center;
      margin-right: 0;
      margin-bottom: 32px;
    }

    &__img {
      margin-top: 0;
    }
  }
}

.course-tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0 32px;

  &__tag {
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgba(253, 200, 64, 0.9);
    color: rgb(88, 80, 55);
    background-color: rgba(253, 200, 64, 0.3);
    padding: 8px 16px;
    margin: 4px;
    border-radius: 24px;
  }
}

.course-info {
  margin: 32px 16px;
  padding: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: $c-gray-40;
  font-weight: 700;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 8px;

    p {
      margin-right: 8px;
    }
  }

  @media (max-width: 768px) {
    &__item {
      width: 50%;
      padding: 16px 8px;
    }
  }
  @media (max-width: 500px) {
    flex-direction: column;
    &__item {
      width: auto;
    }
  }
}

.course-objectives {
  margin: 32px 16px;
  padding: 32px;
  border-radius: 16px;
  color: $c-gray-40;
  font-weight: 700;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

  &__title {
    font-size: 20px;
    padding: 8px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 18px;
    padding: 8px;
    width: 50%;

    p {
      margin-right: 8px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    &__item {
      width: auto;
    }
  }
}

.register-modal {
  background-color: $c-white;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  outline: none;
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  max-width: 400px;
  text-align: center;

  &__title {
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 16px;
  }

  &__confirm {
    background-color: #29a877;
    color: $c-white;
    border: 1px solid rgba(37, 93, 173, 0.25);
    padding: 8px 16px;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(41, 168, 119, 0.35);
    font-size: 16px;
    margin: 16px;
    cursor: pointer;

    &:hover {
      background-color: #249468;
    }
  }

  &__cancel {
    background-color: #f15d5d;
    color: $c-white;
    border: 1px solid rgba(37, 93, 173, 0.25);
    padding: 8px 16px;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0 4px 10px #f15d5d;
    font-size: 16px;
    margin: 16px;
    cursor: pointer;

    &:hover {
      background-color: #df5555;
    }
  }
}

.api-loader {
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-page-input__title {
  width: 70%;
}

.course-page-input__title:focus {
  border-bottom: solid 2px black !important;
}

.course-page-discount-button {
  background-color: #0000ff !important;
  margin-top: 0px !important;
  box-shadow: 0 4px 10px #0000ff !important;
}

.register-modal__price-text {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  // text-decoration-line: line-through !important;
}

.register-modal__last-price-text {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-decoration-line: line-through !important;
}

.register-modal__discount-button {
  height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
